body{
  position: relative;
  background: url("../src/assets/img/bg-test.png");
}

#root{
background: rgba(255, 255, 255, 0.6);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-primary, .active {
  background-color: #BB982C !important;
  border: #BB982C solid 1px !important;
  color: #fff !important;
}

#title-color,.nav-link:not(.active) {
  color: #BB982C !important;
}
.title-color {
  color: #BB982C !important;
}

.card {
  background-color: #fff !important;
  border-radius: 1rem !important;
}
.card-custom{
border-radius: 0 !important;
}
.card-custom-bg{
  background-color: #dadada !important;
}
#card-finance {
  background-color: #eff2fe !important;
}

#card-digital {
  background: #fdeefb !important;
  /* background-color: bisque !important; */
}

#card-management{
  background: #e8fdff !important;
}
#card-keyanalysis{
  background:#CAFFB9 !important;
}
#card-keyanalysis-results{
  background:#FFF !important;
}
#card-operations {
  /* background: #fdeefb !important; */
  background-color: #a2dccf7a !important;
}
#card-resources{
  background-color: #fff2a3eb !important;
}
#card-finished{
  background-color: #ccbfffa3 !important;
}

.bg-primary-gradient {
  background: linear-gradient(135deg, #f8faff, #fef7fd);
  }
.form-control,.btn{
  border-radius: 1rem !important;
  padding: .6rem 1.5rem !important;
}
a{
  color: #4e5d78;
text-decoration: none;
transition: all .2s;
}

.table-danger-custom{
background-color: rgb(236	96	90) !important;
color: white;
vertical-align:middle
}
.table-warning-custom{
  background-color: #FFBF00 !important;
  color: black;
  vertical-align:middle
  }
  .table-success-custom{
    background-color: rgb(127	187	86) !important;
    color: white;
    vertical-align:middle
    }